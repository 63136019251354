import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { HTMLContent } from '../../components/Content';
import Tag from '../Tag';
import Project from './Project';

const ProjectList = ({ data }) => {
  const projects = data.allMarkdownRemark.edges.map(edge => ({
    title: edge.node.frontmatter.title,
    url: edge.node.frontmatter.url,
    technologies: edge.node.frontmatter.technologies,
    screenshot:
      edge.node.frontmatter['screenshot'] != null
        ? {
            src: edge.node.frontmatter['screenshot'].childImageSharp.fluid.src,
            name:
              edge.node.frontmatter['screenshot'].childImageSharp.fluid
                .originalName,
          }
        : undefined,
    screenshotMobile:
      edge.node.frontmatter['screenshotMobile'] != null
        ? {
            src:
              edge.node.frontmatter['screenshotMobile'].childImageSharp.fluid
                .src,
            name:
              edge.node.frontmatter['screenshotMobile'].childImageSharp.fluid
                .originalName,
          }
        : undefined,
    description: edge.node.html,
  }));

  return (
    <Tag tag="ul">
      {projects.map(project => (
        <Tag tag="li" key={project.title}>
          <Project {...project} contentComponent={HTMLContent} />
        </Tag>
      ))}
    </Tag>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query ProjectListQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "project" } } }
        ) {
          edges {
            node {
              html
              frontmatter {
                title
                url
                technologies
                screenshot {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 80) {
                      src
                      originalName
                    }
                  }
                }
                screenshotMobile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 80) {
                      src
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <ProjectList data={data} />}
  />
);
