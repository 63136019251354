import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Tag from '../components/Tag';
import Headline from '../components/Headline';
import Import from '../components/Import';
import Component from '../components/Component';
import { ProjectList } from '../components/Project';
import Content, { HTMLContent } from '../components/Content';

export const PortfolioPageTemplate = ({ title, intro, contentComponent }) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <div style={{ marginBottom: '2rem' }}>
        <Import importString="React" path="react" />
      </div>

      <Component name="Portfolio">
        <Tag tag="div">
          <Headline level="1">{title}</Headline>

          <Tag tag="div" displayName="p">
            <PostContent content={intro} />
          </Tag>

          <ProjectList />
        </Tag>
      </Component>
    </>
  );
};

const PortfolioPage = ({ data }) => {
  const { markdownRemark: page } = data;

  return (
    <>
      <SEO title={page.frontmatter.title} />

      <PortfolioPageTemplate
        title={page.frontmatter.title}
        intro={page.html}
        contentComponent={HTMLContent}
      />
    </>
  );
};

export default PortfolioPage;

export const portfolioPageQuery = graphql`
  query PortfolioPage {
    markdownRemark(frontmatter: { templateKey: { eq: "portfolio-page" } }) {
      html
      frontmatter {
        title
        contact {
          headline
          links {
            text
            link
          }
        }
      }
    }
  }
`;
