import styled from 'styled-components';
import ReactTooltipComponent from 'react-tooltip';

export const ReactTooltip: typeof ReactTooltipComponent = styled(
  ReactTooltipComponent,
)`
  &.show {
    opacity: 1 !important;

    @media (max-width: 768px) {
      top: 50% !important;
      left: 50% !important;
      padding: 1rem !important;
      transform: translate(-50%, -50%) !important;
    }
  }
`;
