import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Content from '../../components/Content';
import Tag from '../../components/Tag';
import { sizes } from '../../styles/utils';
import * as Styled from './Project.styles';

const URL = ({ url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {url}
  </a>
);

const Screenshot = ({ screenshot, title }) => (
  <>
    <button data-tip data-for={title}>
      {screenshot.name}
    </button>

    <Styled.ReactTooltip
      id={title}
      overridePosition={(
        { left, top }: any,
        currentEvent: any,
        currentTarget: any,
        node: { clientWidth: number; clientHeight: number },
      ) => {
        const d = document.documentElement;
        left = Math.min(d.clientWidth - node.clientWidth, left);
        top = Math.min(d.clientHeight - node.clientHeight, top);
        left = Math.max(0, left);
        top = Math.max(0, top);
        return { top, left };
      }}
    >
      <img src={screenshot.src} alt={title} style={{ maxWidth: '90vw' }} />
    </Styled.ReactTooltip>
  </>
);

type Props = {
  title: string;
  technologies: Array<string>;
  screenshot?: {
    name: string;
    src: string;
  };
  screenshotMobile?: {
    name: string;
    src: string;
  };
  url?: string;
  description?: string;
  contentComponent?: any;
};

type Attributes = {
  title: string;
  technologies: string;
  image?: any;
  url?: any;
  description?: string;
};

const Project = ({
  title,
  screenshot,
  screenshotMobile,
  technologies,
  url,
  description,
  contentComponent,
}: Props) => {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${sizes.md}px)`,
  });

  const ProjectDescription = contentComponent || Content;

  const attributes: Attributes = {
    title,
    url: url && <URL url={url} />,
    technologies: technologies && technologies.join(', '),
    image: screenshot && <Screenshot screenshot={screenshot} title={title} />,
  };

  if (isDesktop && screenshot) {
    attributes.image = <Screenshot screenshot={screenshot} title={title} />;
  }

  if (!isDesktop && screenshotMobile) {
    attributes.image = (
      <Screenshot screenshot={screenshotMobile} title={title} />
    );
  }

  if (!url) {
    delete attributes.url;
  }

  if (!attributes.image) {
    delete attributes.image;
  }

  return (
    <Tag
      tag="article"
      displayName="Project"
      attributes={attributes}
      newLineAttributes
    >
      {description && <ProjectDescription content={description} />}
    </Tag>
  );
};

export default Project;
